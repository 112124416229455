<template>
    <div class="content_wpr">
        <div class="section_content w-100">
            <div class="acton_header">
                <h2>Abandoned Carts</h2>
                <div class="client_info">
                    <div>
                        <h5>{{ selectedClient.full_name }}</h5>
                        <h6>{{ selectedClient.email }}</h6>
                    </div>
                    <img :src="selectedClient.profile_pic ? selectedClient.profile_pic : require('@/assets/images/male-female.png')" alt="">
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions mt-4">
                    <ul>
                        <li class="search_area ml-auto" :class="{'active': searchField}">
                            <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="params.search"/>
                            <button class="search_btn" @click="searchField = !searchField;">
                            <i class="fas fa-search"></i>
                            </button>
                        </li>
                        <li class="break_line"></li>
                    </ul>
                </div>
                <div class="contact_loader" v-if="abandonedOrdersLoader">
                    <quote-loader />
                </div>
                <table class="show_header" v-show="!abandonedOrdersLoader">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Cart Recovery Stage</th>
                            <th>Status</th>
                            <!-- <th>Actions</th> -->
                        </tr>
                    </thead>
                    <tbody v-if="abandonedOrders.total">
                        <tr v-for="(order, o) in abandonedOrders.data" :key="o">
                            <td><a @click="handleOrderDetails(order)">{{ order.id }}</a></td>
                            <td>{{ moment(order.created_at).format('ll | LT') }}</td>
                            <td>
                                <div class="user_wpr">
                                    <h4>{{ selectedClient.full_name }}</h4>
                                </div>
                            </td>
                            <td>{{ order.product ? order.product.name : '-' }}</td>
                            <td>{{ companyCurrencySymbol }}{{ order.product && order.product.product_price && order.product.product_price.total ? parseFloat(order.product.product_price.total).toFixed(2) : '0.00' }}</td>
                            <td>{{ order.abandoned_email }}</td>
                            <td><label :class="`abandoned-${order.abandoned_status.toLowerCase().replaceAll(' ', '-')}`">{{ order.abandoned_status }}</label></td>
                            <!-- <td>
                                <ul class="action_list">
                                    <li title="Action"><i class="fas fa-paper-plane"></i></li>
                                </ul>
                            </td> -->
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6" class="px-4 text-center">No Records Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_footer">
                <ul>
                    <li>{{ abandonedOrders.from ? abandonedOrders.from : 0 }} - {{ abandonedOrders.to ? abandonedOrders.to : 0 }} of {{ abandonedOrders.total }}</li>
                </ul>
            </div>
            <div class="pagination" v-show="abandonedOrders.total">
                <pagination v-model="params.page" :pages="abandonedOrders.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
    </div>

    <order-details v-model="orderDetails" :selected-client="selectedClient" :selected-order="selectedOrder" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment'

    const OrderDetails = defineAsyncComponent(() => import('@/pages/checkout/components/client/OrderDetails'))

    export default {
        name: 'Client Abandoned Cart',

        data () {
            return {
                orderDetails: false,
                params: {
                    client_id: '',
                    page: 1,
                    per_page: 10,
                    search: '',
                },
                isTyping: false,
                moment,
                selectedOrder: {},
                searchField: false,
            }
        },

        props: {
            selectedClient: {
                type: Object,
                default: () => {},
            },
        },

        emits: ['update:modelValue'],

        watch: {
            'params.search' (val) {
                const vm = this;
                vm.params.search = val;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2 || vm.params.search.length == 0) {
                            vm.params.page = 1;
                            vm.getClientAbandonedOrders(vm.params);
                        }
                    }
                }
            },
        },

        components:{
            OrderDetails,
        },

        computed: mapState({
            abandonedOrdersLoader: state => state.checkoutModule.clientAbandonedOrdersLoader,
            abandonedOrders: state => state.checkoutModule.clientAbandonedOrders,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
        }),

        mounted () {
            const vm = this;

            vm.resetForm(vm.params);
        },

        methods:{
            ...mapActions({
                getClientAbandonedOrders: 'checkoutModule/getClientAbandonedOrders',
            }),

            resetForm () {
                const vm = this;

                vm.params.client_id = vm.selectedClient.id;
                vm.getClientAbandonedOrders(vm.params);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getClientAbandonedOrders(vm.params);
            },

            handleOrderDetails (order) {
                const vm = this;

                // vm.selectedOrder  = order;
                // vm.orderDetails   = true;
            },
        }
    }
</script>

<style scoped>
    .form_grp h4 {
        font-size: 15px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 300;
        margin-top: 12px;
        display: flex;
    }
    .form_grp h4 .btn {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        background: #2f7eed;
        color: #fff;
        cursor: pointer;
        margin-left: 10px;
    }

    .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: auto;
        padding: 20px;
        text-align: left;
    }

    .result_wpr table td a{
        color: #2f7eed;
        font-weight: 500;
        cursor: pointer;
    }

    .result_wpr table td .action_list{
        display: flex;
        gap: 10px;
    }

    .result_wpr table td .action_list li i{
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
    }

    .result_wpr table td label {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        border: 1px solid #9FB1CF;
        border-radius: 11px;
        padding: 3px 10px;
    }

    .form_grp input.no_spin::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }

    .form_grp input[type=number].no_spin {
        -moz-appearance: textfield;
    }

    .result_wpr table td label.abandoned-expired {
        border-color: #eb1414;
        color: #eb1414;
    }

    .result_wpr table td label.abandoned-subscribed,
    .result_wpr table td label.abandoned-order-placed {
        border-color: #30C13E;
        color: #30C13E;
    }
</style>
